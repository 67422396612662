<template>
  <Page>
    <Instruction v-if="state.error" @close="onAlertClose" level="alert">
      <p v-html="state.error"></p>
      <a class="in-alert" @click="onInquiryButtonClick">お問い合わせ</a>
    </Instruction>
    <div
      class="upload-area"
      @dragover.prevent
      @drop.prevent="onFileDrop"
      :class="{
        disabled:
          !state.membership ||
          state.membership.quota === 0 ||
          state.translation_file,
      }"
    >
      <div
        :class="{
          'uk-alert-danger':
            state.membership &&
            state.membership.contract !== -1 &&
            !state.membership.quota,
          'uk-alert-warning':
            state.membership &&
            state.membership.contract !== -1 &&
            state.membership.quota === 1,
          'uk-alert-primary':
            state.membership &&
            (state.membership.contract === -1 || state.membership.quota > 1),
        }"
        uk-alert
      >
        <div
          v-if="state && state.membership && state.membership.contract !== -1"
          class="instruction"
        >
          <table>
            <tr>
              <th>契約プラン</th>
              <td>
                <span class="uk-text-bold">{{
                  state.membership.plan.name
                }}</span>
                &nbsp;<span v-if="state.membership.plan.code !== 'enterprise2'"
                  >({{
                    state.membership.plan.quota ?? state.membership.contract
                  }}回 / 月)</span
                >
              </td>
            </tr>
            <tr>
              <th>翻訳可能回数</th>
              <td>
                <span class="uk-text-bold">{{ state.membership.quota }}</span
                >&nbsp;回
              </td>
            </tr>
            <tr v-if="state.membership.plan.code !== 'enterprise2'">
              <th>次回更新日</th>
              <td>
                <span class="uk-text-bold">{{
                  state.membership.contract_renewal_date
                }}</span>
              </td>
            </tr>
          </table>
          <div class="short">
            <div>
              <span class="uk-text-bold">{{ state.membership.plan.name }}</span>
              &nbsp;<span v-if="state.membership.plan.code !== 'enterprise2'"
                >({{
                  state.membership.plan.quota ?? state.membership.contract
                }}回 / 月)</span
              >
            </div>
            <div>
              残り&nbsp;<span class="uk-text-bold">{{
                state.membership.quota
              }}</span
              >&nbsp;回
            </div>
            <div v-if="state.membership.plan.code !== 'enterprise2'">
              次回&nbsp;<span class="uk-text-bold">{{
                state.membership.contract_renewal_date
              }}</span
              >&nbsp;更新
            </div>
          </div>
        </div>
        <div class="upload-box">
          <label v-if="!state.translation_file" for="file-input">
            <div>
              <div
                class="uk-margin-medium-bottom upload-icon"
                v-if="!state.translation_file"
                :uk-icon="`icon: ${
                  !state.membership || state.membership.quota === 0
                    ? 'close'
                    : 'plus'
                }; ratio: 3`"
              ></div>
            </div>
            <div
              class="upload-instruction"
              v-if="
                !state.translation_file &&
                state.membership &&
                (state.membership.quota > 0 || state.membership.contract === -1)
              "
            >
              クリックしてファイルを選択<br />
              または、ファイルをドラッグ＆ドロップ
            </div>
            <div
              class="disable-instruction"
              v-if="
                !state.translation_file &&
                state.membership &&
                state.membership.quota === 0
              "
            >
              翻訳可能回数がありません<br />
              <router-link
                to="/plan"
                class="change-plan"
                v-if="state.membership && state.membership.plan.isFree"
              >
                プランを変更する
              </router-link>
            </div>
          </label>
          <div v-else>
            <div class="uploading-instruction">
              <div>
                <div
                  class="uk-margin-small-bottom"
                  :uk-icon="`icon: file-pdf; ratio: 4`"
                >
                  <a
                    href="javascript:void(0);"
                    uk-icon="close"
                    @click.stop="onUploadFileCloseButtonClick"
                    :disabled="state.translating"
                  ></a>
                </div>
                <div class="file-info" v-if="state.translation_file">
                  <div class="file-name">
                    {{ fileName(state.translation_file.name) }}
                  </div>
                  <div class="file-size">
                    {{ fileSize(state.translation_file.size) }}
                  </div>
                </div>
                <button
                  class="uk-button uk-button-primary uk-margin-small-top uk-button-large"
                  :disabled="state.translating || state.translated_file"
                  @click.stop="onUploadButtonClick"
                >
                  <div>
                    <span uk-icon="upload"></span
                    ><span class="button-text">翻訳開始</span>
                  </div>
                </button>
              </div>
              <div>
                <div class="uk-margin-small-bottom">
                  <div
                    :uk-icon="`icon: file-text; ratio: 4`"
                    :class="{
                      translated: state.translated_file && !state.translating,
                    }"
                  ></div>
                  <span
                    class="loader-inner ball-pulse"
                    v-if="state.translating"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </span>
                </div>
                <div class="file-info">
                  <div class="file-name">
                    {{
                      fileName(
                        state.translated_file?.name ||
                          `${state.translation_file.name}_翻訳済.doc`
                      )
                    }}
                  </div>
                </div>
                <button
                  class="uk-button uk-button-primary uk-margin-small-top uk-button-large"
                  :disabled="
                    !state.translated_file ||
                    state.downloading ||
                    state.translating
                  "
                  @click="onDownloadButtonClick"
                >
                  <div v-if="!state.downloading">
                    <span uk-icon="download"></span
                    ><span class="button-text">ダウンロード</span>
                  </div>
                  <div v-else uk-spinner></div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="state.membership && ['trial', 'academic', 'basic'].includes(state.membership.plan.code)"
             class="uk-card notes">
          <div class="uk-card-body uk-padding-remove-top uk-padding-remove-bottom">
            <div>◆ご確認ください◆</div>
            <hr>
            <div class="uk-text-left uk-text-small">
              <div>&nbsp;対象： トライアルプラン・アカデミックプラン・ベーシックプラン</div>
              <ul class="uk-list">
                <li>【翻訳可能な形式】
                  <ul>
                    <li>
                      <div>文字データが埋め込まれている（文字データをコピー＆ペーストできる）PDF のみ</div>
                    </li>
                  </ul>
                </li>
                <div class="uk-margin-remove-top uk-margin-small-left uk-padding-remove notes-annotation">
                  &nbsp;＊上記に当てはまっても、ファイルの状況次第では翻訳に不具合が生じる可能性もございます。ご了承ください。
                </div>
                <li>【翻訳できない形式】
                  <ul>
                    <li>文字データが埋め込まれていない（文字データをコピー＆ペーストできない）PDF
                      <ul>
                        <li>例： スキャンファイルの PDF、フォント情報がない特殊な PDF</li>
                        <li>
                          <router-link to="/plan" class="change-plan">
                            スタンダードプラン・エンタープライズプランなら翻訳可能です
                          </router-link>
                        </li>
                        <div class="uk-margin-remove-top uk-margin-small-bottom uk-padding-remove notes-annotation">
                          &nbsp;＊ファイルの状況次第では翻訳に不具合が生じる可能性もございます。
                        </div>
                      </ul>
                    </li>
                    <li>PDF ではないファイル（jpg / png などの画像、MS Word / Excel）</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div uk-modal ref="inquiryModalRef">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <InquiryForm
          @submitted="onInquirySubmitted"
          :key="state.inquiryFormKey"
          :usageId="state.usage && state.usage.id"
        />
      </div>
    </div>
    <div uk-modal="bg-close: false;" ref="evaluationModal">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <EvaluationForm
          :usageId="state.usage.id"
          v-if="state.usage"
          @submit="onSubmit"
          :key="state.evaluationFormKey"
        />
      </div>
    </div>
    <input
      style="display: none"
      name="file"
      type="file"
      accept=".pdf"
      id="file-input"
      ref="fileInput"
      @change="onUploadFileChange"
      :disabled="
        !state.membership ||
        state.membership.quota === 0 ||
        state.translation_file
      "
      v-clear-instruction
    />
  </Page>
</template>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  margin-top: $spacing-medium;
}
.upload-area {
  width: 100%;
}
.upload-title-box {
  width: 100%;
  border-bottom: solid 2px black;
}
.upload-box {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
  }
  button {
    width: 200px;
    .uk-spinner {
      color: $brand-color;
    }
  }
  & > label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.upload-icon {
  padding: 1rem;
  background-color: white;
  border-radius: 50%;
}
.upload-area a {
  text-decoration: underline;
  font-weight: bold;
}
.upload-area.disabled {
  a {
    &.change-plan {
      color: $color-danger !important;
    }
  }
}
.file-info {
  min-height: 60px;
}
.file-name {
  word-break: break-all;
}
.instruction {
  text-align: left;
  th {
    padding-right: $spacing-medium;
  }
}
.upload-area [uk-alert] {
  padding: $spacing-large;
}
.short {
  display: none;
}
@media screen and (max-width: 480px) {
  .page-container {
    margin-top: $spacing-ex-small;
  }
  .short {
    display: block;
  }
  .upload-area [uk-alert] {
    padding: $spacing-ex-small;
    height: 100%;
    padding-top: $spacing-medium;
  }
  .upload-area {
    height: calc(100vh - 148px);
  }
  .instruction table {
    display: none;
  }
  .upload-box {
    button {
      width: 120px;
    }
  }
  .button-text {
    display: none;
  }
}
.uploading-instruction {
  display: flex;
  align-items: baseline;
  position: relative;
  color: $brand-color;
  & > div:first-child,
  > div:last-child {
    flex: 1;
  }
  & > div:first-child > div {
    position: relative;
    a {
      position: absolute;
      top: -5px;
      right: 0;
      border: 1px solid;
      border-radius: 50%;
      font-weight: bold;
      background: $brand-color;
      color: #fff !important;
      z-index: 10;
      &[disabled="true"] {
        display: none;
      }
    }
  }
  & > div:last-child {
    position: relative;
    div[uk-icon] {
      opacity: 0.2;
      &.translated {
        opacity: 1;
      }
    }
    .ball-pulse {
      position: absolute;
      top: calc(50% - 19px / 2);
      left: calc(50% - 57px / 2);
      transform: scale(0.7, 0.7);
    }
    .ball-pulse > div {
      background-color: $brand-color;
    }
  }
}
.upload-button {
  color: $brand-color;
}
.notes {
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 1rem;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 10px;
  li > ul > li {
    padding-bottom: 0.1rem;
  }
  li > ul > li > ul > li {
    padding-top: 0.2rem;
  }
  li > ul > li > ul > li:last-child {
    padding-bottom: 0.5rem;
  }
}
.notes-annotation {
  font-size: 0.7rem;
  margin-bottom: 1.2rem;
}
.in-alert {
  @include inherited-color-link;
}
</style>
<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";
import Page from "../atoms/Page.vue";
import {
  GetMyProfileResponseMembership,
  GetMyUsage,
  MyApi,
  Profile,
} from "../../autogen";
import Instruction from "../atoms/Instruction.vue";
import Moment from "moment";
import { download } from "../../helpers/dom-utils";
import UIkit from "uikit";
import InquiryForm from "../molecules/InquiryForm.vue";
import EvaluationForm from "../molecules/EvaluationForm.vue";
type State = {
  disabled: boolean;
  profile: Profile | null;
  membership: GetMyProfileResponseMembership | null;
  translation_file: File | null;
  translating: boolean;
  translated_file: { name?: string; size?: number } | null;
  error: string | null;
  usage: GetMyUsage | null;
  evaluationFormKey: number;
  inquiryFormKey: number;
  downloading: boolean;
};
export default defineComponent({
  components: { Page, Instruction, InquiryForm, EvaluationForm },
  setup() {
    const myApi = new MyApi();
    const fileInput = ref<HTMLInputElement>();
    const evaluationModal = ref<HTMLElement>();
    const state: State = reactive({
      disabled: true,
      profile: null,
      membership: null,
      translation_file: null,
      translating: false,
      translated_file: null,
      error: null,
      usage: null,
      evaluationFormKey: 0,
      inquiryFormKey: 0,
      downloading: false,
    });

    async function updateState() {
      const response = await myApi.getMyProfile({});
      state.profile = response.data.profile;
      state.membership = response.data.membership;
      state.membership.contract_renewal_date = Moment(
        response.data.membership.contract_renewal_date
      ).format("YYYY年MM月DD日");
    }
    onBeforeMount(async () => {
      await updateState();
    });
    const onUploadFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files![0].size > 52428800) {
        fileInput.value!.value = "";
        setTimeout(() => {
          state.error = "翻訳ファイルのサイズの上限は50MBです";
        });
        return;
      }
      state.translation_file = target.files![0];
    };
    const onFileDrop = (event: DragEvent) => {
      if (state.membership!.quota === 0) return;
      const files = event.dataTransfer!.files;
      state.translation_file = files[0];
    };
    const onUploadFileCloseButtonClick = () => {
      fileInput.value!.value = "";
      state.translation_file = null;
      state.translating = false;
      state.translated_file = null;
      state.error = null;
    };
    let interval: number;
    const onUploadButtonClick = async () => {
      state.translating = true;
      state.error = null;
      state.usage = null;
      try {
        const uploadResponse = await myApi.uploadMyFile({
          file: state.translation_file!,
        });
        // const uploadResponse = { status: 200, data: { id: 0 } };
        if (uploadResponse.status === 200) {
          interval = window.setInterval(async () => {
            const usageResponse = await myApi.getMyUsage({
              usageId: uploadResponse.data.id!,
            });
            // 翻訳中じゃない
            if (usageResponse.data.status && usageResponse.data.status !== 202) {
              state.translating = false;
              clearInterval(interval);
              // エラー終了
              if (usageResponse.data.status !== 200 && usageResponse.data.status !== 201) {
                if (usageResponse.data.err_state == "1") {
                  state.error = '翻訳に失敗しました<br/>文字データが埋め込まれていない（文字データをコピー＆ペーストできない）PDF です。<br/>翻訳には文字認識（OCR）機能が必要です。<br/>スタンダートもしくはエンタープライズプランをご利用ください。';
                }
                else {
                  state.error = "翻訳に失敗しました";
                }
              }
              // 翻訳完了
              else {
                state.translated_file = {};
                state.usage = usageResponse.data;
              }
            }
          }, 1000);
        }
      } catch (err) {
        console.error(err);
        clearInterval(interval);
        state.translating = false;
        state.error = "翻訳に失敗しました";
      }
    };
    const onDownloadButtonClick = async () => {
      state.error = null;
      state.downloading = true;
      try {
        const response = await myApi.downloadMyFile(
          {
            usageId: state.usage!.id,
          },
          {
            responseType: "arraybuffer",
          }
        );
        // const timeout = () => {
        // 	return new Promise((resolve, reject) => {
        // 		setTimeout(() => {
        // 			resolve(null)
        // 		}, 5000)
        // 	})
        // }
        // await timeout()
        // const response = {data: 'aaaa'}
        await updateState();
        download(`${state.translation_file!.name}_翻訳済.docx`, response.data);
        document.body.onfocus = () => {
          if (evaluationModal.value) {
            state.evaluationFormKey = Date.now();
            UIkit.modal(evaluationModal.value).show();
          }
          document.body.onfocus = null;
        };
      } catch (err) {
        state.error = "ダウンロードに失敗しました";
      }
      state.downloading = false;
    };
    onBeforeUnmount(() => {
      if (interval) {
        clearInterval(interval);
      }
    });
    const onAlertClose = () => {
      state.error = null;
    };
    const onInquiryButtonClick = () => {
      state.inquiryFormKey = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const inquiryModalRef = ref<HTMLElement>();
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const onSubmit = () => {
      if (evaluationModal.value) {
        UIkit.modal(evaluationModal.value).hide();
      }
    };
    const fileSize = (size?: number) => {
      return size !== undefined
        ? `${Math.round((size / 1024 / 1024) * 10) / 10}MB`
        : "-";
    };
    const fileName = (name: string) => name;
    return {
      fileInput,
      evaluationModal,
      state,
      onUploadFileChange,
      onUploadFileCloseButtonClick,
      onUploadButtonClick,
      onAlertClose,
      onFileDrop,
      onDownloadButtonClick,
      onInquiryButtonClick,
      onInquirySubmitted,
      inquiryModalRef,
      onSubmit,
      fileSize,
      fileName,
    };
  },
});
</script>
