<template>
  <div>
    <h2>翻訳結果はいかがでしたか？</h2>
    <form @submit="onSubmit">
      <star-rating v-model:rating="state.rating"></star-rating>
      <textarea
        class="uk-textarea"
        rows="5"
        v-model="comment"
        :disabled="isSubmitting"
      ></textarea>
      <div class="evaluation-modal-actions">
        <button
          class="uk-button uk-button-default uk-modal-close"
          type="button"
          v-ga-track="{ action: 'withdraw-evaluation-form' }"
        >
          閉じる
        </button>
        <button
          :disabled="isSubmitting"
          class="uk-button uk-button-primary"
          type="submit"
          v-ga-track="{ action: 'submit-evaluation-form' }"
        >
          送信
        </button>
      </div>
    </form>
  </div>
</template>
<style lang="scss" scoped>
.vue-star-rating {
  margin-bottom: $spacing-ex-small;
}
.evaluation-modal-actions {
  margin-top: $spacing-medium;
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss">
span.vue-star-rating-rating-text {
  display: none;
}
</style>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
// @ts-ignore
import StarRating from 'vue-star-rating';
import { useForm, useField } from 'vee-validate';
import { MyApi } from '../../autogen';
export default defineComponent({
  components: { StarRating },
  props: {
    usageId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const myApi = new MyApi();
    const state = reactive({ rating: 0 });
    const { handleSubmit, isSubmitting, resetForm } = useForm<{
      comment: string;
    }>();
    const { value: comment } = useField('comment');
    const onSubmit = handleSubmit(async (values) => {
      try {
        await myApi.evaluateTranslation({
          usageId: props.usageId,
          evaluateTranslationRequest: {
            score: state.rating || 0,
            comment: values.comment,
          },
        });
        context.emit('submit');
        resetForm();
        state.rating = 0;
      } catch (err) {
        console.error(err);
      }
    });
    return { onSubmit, isSubmitting, comment, state };
  },
});
</script>
