
import { defineComponent, onBeforeMount, onBeforeUpdate, ref } from "vue";
import UsagePaging from "../atoms/UsagePaging.vue";
import UsageListHolder from "../atoms/UsageListHolder.vue";
import { useUsage } from "../compositions/useUsage";
import {
  LocationQuery,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRoute,
} from "vue-router";
import { useNotification } from "../compositions/useNotification";
import { MyApi } from "../../autogen";

export default defineComponent({
  name: "Home",
  components: { UsageListHolder, UsagePaging },
  setup() {
    // reactive objects
    const usages = useUsage();

    // 論文の取得処理
    // パスのmodeにenterpriseが指定されており、且つuserIdが指定されている時は
    // エンタープライズ用のAPIへリクエストを投げる
    const getPapers = async (query: LocationQuery) => {
      const profile = await new MyApi().getMyProfile({});

      // パスの確認
      const isEnterprise = query.mode === "enterprise";
      usages.isOrganizationUser.value = !!profile.data.organization && !isEnterprise;
      const mode = isEnterprise ? "enterprise" : "my";
      let userId: number | null = null;
      if (query.userId && !Array.isArray(query.userId)) {
        userId = parseInt(query.userId);
      }
      // リストの更新
      const page = 0;
      usages.clear();
      usages.load(mode, userId, page);
    };

    // laod papers
    const locationQuery = useRoute().query;
    onBeforeMount(async () => await getPapers(locationQuery));
    onBeforeRouteUpdate((to, from, next) => {
      getPapers(to.query);
      next();
    });
    const onRadioChange = async () => await getPapers(locationQuery);

    return {
      usages: usages.list,
      isOrganizationUser: usages.isOrganizationUser,
      isRequestAll: usages.isRequestAll,
      onRadioChanged: onRadioChange,
    };
  },
});
