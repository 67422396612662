
import { defineComponent, onBeforeMount, reactive, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { UserApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import Instruction from '../atoms/Instruction.vue';
import { useI18n } from 'vue-i18n';
import { Instruction as InstructionType } from '../../types';
type State = {
  instruction?: InstructionType;
  verified?: boolean;
  verifying: boolean;
};
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const state: State = reactive({ verifying: true });
    onBeforeMount(async () => {
      try {
        await new UserApi().verify({
          authorization: new URLSearchParams(location.search).get('token')!,
        });
        // throw new Error()
        state.verified = true;
        state.instruction = {
          level: 'info',
          message: 'メールアドレスを確認しました',
        };
      } catch (err) {
        console.error(err);
        state.verified = false;
        state.instruction = {
          level: 'alert',
          message:
            'メールアドレスの確認に失敗しました<br/>URLが不正または期限切れです',
        };
      }
      state.verifying = false;
    });
    const { t } = useI18n();
    const router = useRouter();
    const { handleSubmit, isSubmitting } = useForm({
      validationSchema: yup.object({
        identifier: yup
          .string()
          .required(t('required', { field: 'ユーザ名またはメールアドレス' })),
      }),
    });
    const { value: identifier } = useField('identifier');
    const onSubmit = handleSubmit(async (values) => {
      state.instruction = undefined;
      nextTick(async () => {
        try {
          await new UserApi().requestVerification({
            requestVerificationRequest: { identifier: values.identifier! },
          });
          state.instruction = {
            level: 'info',
            message: '登録されたメールアドレスに認証メールを送信しました',
          };
        } catch (err) {
          state.instruction = {
            level: 'alert',
            message: '認証メールの送信に失敗しました',
          };
        }
      });
    });
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    const onLoginButtonClick = () => {
      router.push('/login');
    };
    return {
      t,
      state,
      onSubmit,
      isSubmitting,
      identifier,
      onAlertClose,
      onLoginButtonClick,
    };
  },
});
