<template>
  <div class="container">
    <table class="uk-table uk-table-divider uk-table-justify">
      <thead>
        <tr class="uk-text-small">
          <th class="uk-table-expand">氏名</th>
          <th class="uk-table-width-small">総本数</th>
          <th class="uk-table-width-small">月平均</th>
          <th class="uk-table-expand" v-if="wnd.isMobile.value">最近の翻訳</th>
          <th class="uk-table-expand" v-if="wnd.isTablet.value">
            最近の翻訳概要
          </th>
          <th v-if="wnd.isTablet.value">図</th>
          <th class="uk-table-width-small">プロフィール</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="member in members" :key="member.id">
          <td class="uk-text-truncate">
            <router-link
              :to="{
                path: 'usage',
                query: member.user_id === userId ? {} : { mode: 'enterprise', userId: member.user_id },
              }"
              >{{ member.last_name }} {{ member.first_name }}
            </router-link>
          </td>
          <td class="uk-text-truncate">{{ member.quota_sum }}</td>
          <td class="uk-text-truncate">{{ member.quota_avg }}</td>
          <td class="uk-text-truncate" v-if="wnd.isMobile.value">
            {{ member.latest_paper_title }}
          </td>
          <td class="uk-text-truncate" v-if="wnd.isTablet.value">
            {{ member.latest_paper_abstract }}
          </td>

          <!-- image -->
          <td class="uk-text-truncate" v-if="wnd.isTablet.value">
            <img
              :src="
                !member.latest_paper_image
                  ? null
                  : `data:image/png;base64,${member.latest_paper_image}`
              "
            />
          </td>

          <td class="uk-text-truncate">
            <router-link
              :to="{
                path: 'profile',
                query: member.user_id === userId ? {} : { mode: 'enterprise', userId: member.user_id },
              }"
            >
              <div
                class="uk-icon-link uk-margin-small-right"
                uk-icon="file-edit"
              />
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { GetEnterpriseUsers } from "../../autogen";
import { useApp } from "../compositions/useApp";
import { useWindow } from "../compositions/useWindow";

export default defineComponent({
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const wnd = useWindow();
    const { userId } = useApp();
    return {
      wnd,
      userId,
    };
  },
});
</script>

<style scoped>
.container {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 0px 15px;
}
.uk-table th {
  text-align: center !important;
}
td {
  color: #666;
}
</style>
