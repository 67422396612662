
import { defineComponent, reactive } from 'vue';
// @ts-ignore
import StarRating from 'vue-star-rating';
import { useForm, useField } from 'vee-validate';
import { MyApi } from '../../autogen';
export default defineComponent({
  components: { StarRating },
  props: {
    usageId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const myApi = new MyApi();
    const state = reactive({ rating: 0 });
    const { handleSubmit, isSubmitting, resetForm } = useForm<{
      comment: string;
    }>();
    const { value: comment } = useField('comment');
    const onSubmit = handleSubmit(async (values) => {
      try {
        await myApi.evaluateTranslation({
          usageId: props.usageId,
          evaluateTranslationRequest: {
            score: state.rating || 0,
            comment: values.comment,
          },
        });
        context.emit('submit');
        resetForm();
        state.rating = 0;
      } catch (err) {
        console.error(err);
      }
    });
    return { onSubmit, isSubmitting, comment, state };
  },
});
