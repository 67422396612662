<template>
  <legend class="uk-legend uk-margin-small-top">
    {{ title }}<span v-if="required" class="uk-text-danger">*</span>
  </legend>
</template>
<style lang="scss" scoped>
.uk-legend {
  font-size: 1rem !important;
  text-align: left;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
  },
});
</script>
