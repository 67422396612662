
import { defineComponent, onBeforeMount, reactive, ref, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { GetMyPlan, MyApi } from "../../autogen";
import Page from "../atoms/Page.vue";
import Instruction from "../atoms/Instruction.vue";
import { Instruction as InstrctionType } from "../../types";
import InquiryForm from "../molecules/InquiryForm.vue";
import UIkit from "uikit";
import Store from "../../store";
import { useGtag } from "vue-gtag-next";
type AlertModalInfo = {
  actionName: string;
  messages: string[];
  onClick: Function;
};
type State = {
  appliedPlan?: GetMyPlan;
  plans: GetMyPlan[];
  instruction?: InstrctionType;
  inquiryFormKey: number;
  alertModalInfo: AlertModalInfo;
};
export default defineComponent({
  components: { Page, Instruction, InquiryForm },
  setup() {
    const state: State = reactive({
      plans: [],
      inquiryFormKey: 0,
      alertModalInfo: {
        actionName: '',
        messages: [],
        onClick: () => {},
      }
    });
    const api = new MyApi();
    async function updatePlans(plans: GetMyPlan[]) {
      state.plans = plans;
      state.plans.sort((p1, p2) => {
        if (p1.applied) return 1;
        if (p2.applied) return -1;
        return (p1.price || 0) < (p2.price || 0)
          ? 1
          : (p1.price || 0) > (p2.price || 0)
          ? -1
          : 0;
      });
      state.appliedPlan = plans.find(p => p.applied);
    }
    const route = useRoute();
    const router = useRouter();
    const { event } = useGtag();
    onBeforeMount(async () => {
      const response = await api.getMyPlans({});
      updatePlans(response.data);
      const isPaymentSucceeded = route.query.success;
      const isPaymentCanceled = route.query.cancel;
      const paymentPlan = route.query.plan;
      if (isPaymentSucceeded) {
        event("submit-application", { plan: paymentPlan });
        state.instruction = {
          level: "info",
          message: `契約プランを変更しました`,
        };
      } else if (isPaymentCanceled) {
        event("cancel-application", { plan: paymentPlan });
      }
      router.replace({ query: undefined });
    });

    const onApplyButtonClick = async (_plan: GetMyPlan) => {
      state.alertModalInfo = {
        actionName: '申込（ダウングレード）',
        messages: [
          '◆ご注意ください◆',
          '1paperの翻訳サービスは、翻訳時にご契約中のプランで利用可能な機能（https://1paper.jp/plan/）と紐づきます。',
          '下位のプランに変更する場合、プラン変更後の翻訳結果は下位仕様の翻訳結果となります。（例：スタンダードプラン→ベーシックプラン　変更後はスキャンファイルを翻訳できない）',
          '下位プランの申込処理を実行してよろしいですか？',
        ],
        onClick: () => onApplyConfirmed(_plan),
      }
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    const onApplyConfirmed = async (plan: GetMyPlan) => {
      const profile = await api.getMyProfile({});
      // @ts-ignore
      // eslint-disable-next-line no-undef
      const stripe = Stripe(plan.stripe!.pk);
      await stripe.redirectToCheckout({
        mode: "subscription",
        lineItems: [{ price: plan.stripe!.payment_code, quantity: 1 }],
        successUrl: `${location.origin}/plan?success=1&plan=${plan.code}`,
        cancelUrl: `${location.origin}/plan?cancel=1&plan=${plan.code}`,
        customerEmail: profile.data.profile.email,
      });
      if (alertModalRef.value) {
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const onResignButtonClick = async (_plan: GetMyPlan) => {
      // because of v-clear-instruction
      state.alertModalInfo = {
        actionName: '解約',
        messages: [
          '◆ご注意ください◆',
          '退会した場合、残りの翻訳回数は使用できなくなります',
          '退会処理を実行してよろしいですか？',
        ],
        onClick: onResignConfirmed,
      }
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    const onCancelButtonClick = async (_plan: GetMyPlan) => {
      state.alertModalInfo = {
        actionName: '解約',
        messages: [
          '◆ご注意ください◆',
          '1paperの翻訳サービスは、翻訳時にご契約中のプランで利用可能な機能（https://1paper.jp/plan/）と紐づきます。',
          '下位のプランに変更する場合、プラン変更後の翻訳結果は下位仕様の翻訳結果となります。（例：スタンダードプラン→トライアルプラン　変更後は原文注釈がつかない）',
          '解約処理を実行してよろしいですか？',
        ],
        onClick: onCancelConfirmed,
      }
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    const onCancelConfirmed = async (_plan: GetMyPlan) => {
      if (alertModalRef.value) {
        await api.cancelMyPlan({});
        const response = await api.getMyPlans({});
        state.instruction = {
          level: "info",
          message: `契約プランを解約しました`,
        };
        updatePlans(response.data);
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const inquiryModalRef = ref<HTMLElement>();
    const onAskButtonClick = () => {
      state.inquiryFormKey = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    const onResignConfirmed = async () => {
      if (alertModalRef.value) {
        await api.cancelMyPlan({});
        await new MyApi().logout({});
        state.instruction = {
          level: "info",
          message: `退会処理を実行しました<br/>ご利用ありがとうございました`,
        };
        nextTick(() => {
          setTimeout(() => {
            Store.getInstance().removeAuth();
            router.push("/login");
          }, 5000);
        });
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const alertModalRef = ref<HTMLElement>();
    return {
      state,
      onAskButtonClick,
      onApplyButtonClick,
      onApplyConfirmed,
      onCancelButtonClick,
      onResignButtonClick,
      onAlertClose,
      inquiryModalRef,
      alertModalRef,
      onInquirySubmitted,
      onResignConfirmed,
    };
  },
});
