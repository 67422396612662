<template>
  <div class="app-container">
    <router-view name="menu" v-if="!state.isPublic" />
    <div class="view-container" :class="{ public: state.isPublic }">
      <div class="view-wrapper">
        <router-view name="public-header" v-if="state.isPublic" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html {
  overflow-y: scroll;
  overflow-x: hidden;
}
body {
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app-container {
}
.view-container {
  display: flex;
  justify-content: center;
  height: calc(100% - 80px);
  &.public {
    align-items: center;
    height: 100%;
    .view-wrapper {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 480px) {
  #app {
    padding: 0 $spacing-ex-small;
  }
  .view-container {
    height: 100%;
  }
  .view-wrapper {
    width: 100%;
  }
}
</style>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useWindow } from "./components/compositions/useWindow";
export default defineComponent({
  setup() {
    const route = useRoute();
    const state = reactive({ page: route.name, isPublic: true });
    watch(
      () => route.name,
      (name) => {
        state.page = name;
        state.isPublic = [
          "Login",
          "PasswordResetRequest",
          "PasswordReset",
          "Register",
          "Verify",
        ].includes(name as string);
      }
    );

    // ウィンドウサイズ変更時のイベントを追加して、
    // アプリ内で使用するウィンドウに関するパラメータを更新する
    const wnd = useWindow();
    wnd.update();
    window.addEventListener("resize", wnd.update);
    return { state };
  },
});
</script>
