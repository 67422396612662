
import { defineComponent } from 'vue';
import { ErrorMessage } from 'vee-validate';
export default defineComponent({
  components: { ErrorMessage },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
