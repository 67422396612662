<template>
  <div class="container">
    <a href="https://1paper.jp"
      ><img src="@/assets/logo.png" class="uk-margin-medium-bottom"
    /></a>
  </div>
</template>
<style lang="scss" scoped>
.container {
  margin-top: $spacing-large + 32px;
}
@media screen and (max-width: 480px) {
  .container {
    margin-top: $spacing-ex-small;
  }
}
</style>
