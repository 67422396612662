import { ref } from "vue";

const isTablet = ref<boolean>(false);
const isMobile = ref<boolean>(false);

export function useWindow() {
  const update = () => {
    isTablet.value = window.innerWidth >= 1200;
    isMobile.value = window.innerWidth >= 640;
  };

  return {
    isTablet,
    isMobile,
    update,
  };
}
