
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { MyApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import { useI18n } from 'vue-i18n';
import Instruction from '../atoms/Instruction.vue';
import { Instruction as InstructionType } from '../../types';
type State = { instruction?: InstructionType };
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const { handleSubmit, isSubmitting } = useForm({
      validationSchema: yup.object({
        identifier: yup
          .string()
          .required(t('required', { field: 'ユーザ名またはメールアドレス' })),
      }),
    });
    const state: State = reactive({});
    function clearState() {
      state.instruction = undefined;
    }
    const { value: identifier } = useField('identifier');
    const onSubmit = handleSubmit(async (values) => {
      clearState();
      try {
        await new MyApi().remind({
          remindRequest: { identifier: values.identifier! },
        });
        state.instruction = {
          level: 'info',
          message: '登録されたメールアドレスに認証メールを送信しました',
        };
      } catch (err) {
        if (err.response && err.response.status == 404) {
          state.instruction = {
            level: 'alert',
            message: t('identifier not found'),
          };
        } else {
          state.instruction = {
            level: 'alert',
            message: err.message as string,
          };
        }
      }
    });
    const onBackButtonClick = () => {
      router.go(-1);
    };
    const onAlertClose = () => {
      clearState();
    };
    return {
      t,
      state,
      onSubmit,
      isSubmitting,
      identifier,
      onBackButtonClick,
      onAlertClose,
    };
  },
});
