<template v-slot:unauthenticated>
  <div>
    <div v-if="state.instruction">
      <Instruction
        @close="onAlertClose"
        position="relative"
        :level="state.instruction.level"
      >
        <p>{{ state.instruction.message }}</p>
      </Instruction>
    </div>
    <form @submit="onSubmit">
      <fieldset class="uk-fieldset">
        <FieldLabel title="メールアドレス" :required="true" />
        <input class="uk-input" type="text" name="email" v-model="email" />
        <FieldError name="email" />
        <FieldLabel title="お問い合わせ種別" :required="true" />
        <select
          class="uk-select"
          name="type"
          v-model="itype"
        >
          <option
            v-for="(name, value) in inquiryTypes"
            :key="value"
            :value="value"
          >
            {{ name }}
          </option>
        </select>
        <FieldError name="type" />
        <FieldLabel title="お問い合わせ内容" :required="true" />
        <textarea
          class="uk-textarea"
          name="comment"
          rows="5"
          v-model="comment"
          :disabled="isSubmitting"
        ></textarea>
        <FieldError name="comment" />
      </fieldset>
      <div class="form-actions">
        <button
          type="button"
          :disabled="isSubmitting"
          class="uk-button uk-button-default uk-margin-medium-top uk-modal-close"
        >
          閉じる
        </button>
        <button
          type="submit"
          :disabled="isSubmitting"
          class="uk-button uk-button-primary uk-margin-medium-top"
        >
          登録
        </button>
      </div>
    </form>
  </div>
</template>
<style lang="scss" scoped>
@include form-input;
.form-actions {
  display: flex;
  justify-content: space-between;
}
</style>
<script lang="ts">
import { defineComponent, reactive, onBeforeMount } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { InquiryRequest, InquiryTypeEnum, UserApi, MyApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import Instruction from '../atoms/Instruction.vue';
import { useI18n } from 'vue-i18n';
import { Instruction as InstructionType } from '../../types';
import Store from '../../store';

type State = {
  instruction?: InstructionType;
};
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  props: {
    type: {
      type: String,
      default: InquiryTypeEnum.AboutTranslationError,
    },
    usageId: {
      type: Number,
    },
  },
  setup(props, context) {
    const inquiryTypes = {
      [InquiryTypeEnum.AboutTranslationError]: '翻訳について',
      [InquiryTypeEnum.AboutEnterprisePlan]: 'エンタープライズプランについて',
      [InquiryTypeEnum.AboutOthers]: 'その他',
    };
    const state: State = reactive({});
    const { t } = useI18n();
    const { handleSubmit, isSubmitting, resetForm } = useForm<{
      email: string;
      type: string;
      comment: string;
    }>({
      validationSchema: yup.object({
        email: yup
          .string()
          .email(t('email'))
          .required(t('required', { field: 'メールアドレス' })),
        type: yup
          .string()
          .required(t('required', { field: 'お問い合わせ種別' })),
        comment: yup
          .string()
          .required(t('required', { field: 'お問い合わせ内容' })),
      }),
    });
    const { value: email } = useField('email');
    const { value: itype } = useField('type');
    const { value: comment } = useField('comment');
    onBeforeMount(async () => {
      let values = { type: props.type, email: '', comment: '' };
      if (Store.getInstance().getAuth()) {
        const response = await new MyApi().getMyProfile({});
        values.email = response.data.profile.email;
      }
      resetForm({ values });
    });
    const onSubmit = handleSubmit(async (values) => {
      await new UserApi().inquiry({
        inquiryRequest: Object.assign(
          { usage_id: props.usageId },
          values
        ) as InquiryRequest,
      });
      context.emit('submitted');
    });
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    return {
      state,
      onSubmit,
      isSubmitting,
      onAlertClose,
      email,
      itype,
      comment,
      inquiryTypes,
    };
  },
});
</script>
