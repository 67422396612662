
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { MyApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import Instruction from '../atoms/Instruction.vue';
import Store from '../../store';
import { useI18n } from 'vue-i18n';
type State = {
  error: string | null;
};
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { handleSubmit, isSubmitting } = useForm<{
      identifier: string;
      password: string;
    }>({
      validationSchema: yup.object({
        identifier: yup
          .string()
          .required(t('required', { field: 'ユーザ名またはメールアドレス' })),
        password: yup.string().required(t('required', { field: 'パスワード' })),
      }),
    });
    const { value: identifier } = useField('identifier');
    const { value: password } = useField('password');
    const state: State = { error: null };
    const onSubmit = handleSubmit(async (values) => {
      state.error = null;
      try {
        const loginResponse = await new MyApi().login({
          loginRequest: {
            identifier: values.identifier,
            password: values.password,
          },
        });
        Store.getInstance().setAccessToken(loginResponse.data.token);
        Store.getInstance().setAuth(true);
        router.push('/');
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 423:
              state.error =
                'ログインに失敗しました<br/>システム移行に伴い、2021年6月21日以前に登録されたお客様は、お手数ですが、"パスワードを忘れた"リンクから、パスワードをリセットしてください';
              break;
            case 401:
              state.error =
                'ログインに失敗しました<br/>ユーザ名/メールアドレス、またはパスワードの照合に失敗しました';
              break;
            case 403:
              state.error =
                'ログインに失敗しました<br/>メールアドレスの確認が完了していません';
              break;
            default:
              state.error = 'ログインに失敗しました';
          }
        } else {
          state.error = 'ログインに失敗しました';
        }
      }
    });
    const onAlertClose = () => {
      state.error = null;
    };
    return {
      t,
      state,
      onSubmit,
      isSubmitting,
      identifier,
      password,
      onAlertClose,
    };
  },
});
