<template>
  <div v-if="status">
    <div class="status" :style="styles">
      <span class="status uk-text-small"> {{ text }} </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    // set color
    let color = null;
    if (props.status === 200 || props.status === 201) color = "#cdecc6";
    if (props.status === 202) color = "#ece4c6";
    if (props.status === 400 || props.status === 403) color = "#ecc6c6";

    // set text
    let text = null;
    if (props.status === 200 || props.status === 201) text = "翻訳完了";
    if (props.status === 202) text = "翻訳処理中";
    if (props.status === 400 || props.status === 403) text = "翻訳失敗";

    // css settings
    const styles = {
      "--background-color": color,
    };

    return {
      styles,
      text,
    };
  },
});
</script>

<style scoped>
.status {
  /* display: block; */
  border-radius: 5px;
}
span {
  color: #333;
  padding: 0px 4px;
  background-color: var(--background-color);
}
</style>
