
import { defineComponent } from "@vue/runtime-core";
import { GetEnterpriseUsers } from "../../autogen";
import { useApp } from "../compositions/useApp";
import { useWindow } from "../compositions/useWindow";

export default defineComponent({
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const wnd = useWindow();
    const { userId } = useApp();
    return {
      wnd,
      userId,
    };
  },
});
