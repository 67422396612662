
import { defineComponent, onBeforeMount, ref } from "@vue/runtime-core";
import { PropType } from "vue";
import moment from "moment";
import UIkit from "uikit";
import StatusMark from "../atoms/StatusMark.vue";
import Instruction from "../atoms/Instruction.vue";
import { EnterpriseApi, GetMyUsage, MyApi } from "../../autogen";
import { download } from "../../helpers/dom-utils";
import InquiryForm from "../molecules/InquiryForm.vue";
import EvaluationForm from "../molecules/EvaluationForm.vue";
import { useUsage } from "../compositions/useUsage";
import { useRoute } from "vue-router";
import { useApp } from "../compositions/useApp";

export default defineComponent({
  components: { StatusMark, Instruction, InquiryForm, EvaluationForm },
  props: {
    usage: {
      type: Object as PropType<GetMyUsage>,
      required: true,
    },
  },
  setup(props) {
    // create reactive object
    const route = useRoute();
    const usage = useUsage();
    const { userId } = useApp();
    const isActive = ref(false);
    const onHolderClicked = () => (isActive.value = !isActive.value);
    const date = moment(props.usage.uploaded_at).format("YYYY/MM/DD");
    const isImageEnabled = ref(false);
    const isActionEnabled = ref(false);
    const evaluationModalRef = ref<HTMLElement>();
    const inquiryModalRef = ref<HTMLElement>();
    const error = ref<string | null>(null);
    const evaluationFormKey = ref(0);
    const inquiryFormKey = ref(0);

    // エンタープライズ時用の情報を取得
    const isEnterprise = route.query.mode === "enterprise";
    const mode = isEnterprise ? "enterprise" : "my";
    const onDownload = async () => {
      error.value = null;
      try {
        const response = mode == "my" ? await new MyApi().downloadMyFile(
          { usageId: props.usage.id },
          { responseType: "arraybuffer" }
        ) : await new EnterpriseApi().downloadEnterpriseUserFile(
          { usageId: props.usage.id },
          { responseType: "arraybuffer" }
        );
        download(`${props.usage.translation_file}_翻訳済.docx`, response.data);
        document.body.onfocus = () => {
          if (evaluationModalRef.value) {
            evaluationFormKey.value = Date.now();
            UIkit.modal(evaluationModalRef.value).show();
          }
          document.body.onfocus = null;
        };
      } catch (err) {
        error.value = "ダウンロードに失敗しました";
      }
    };
    const onAlertClose = () => (error.value = null);
    const onReportButtonClick = () => {
      error.value = null;
      inquiryFormKey.value = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) UIkit.modal(inquiryModalRef.value).hide();
    };
    const onSubmit = () => {
      if (evaluationModalRef.value)
        UIkit.modal(evaluationModalRef.value).hide();
    };

    // responsive settings
    const responsive = () => {
      isImageEnabled.value = window.innerWidth < 850 ? false : true;
      isActionEnabled.value = window.innerWidth < 540 ? false : true;
    };
    window.addEventListener("resize", responsive);

    // "OPEN"ボタンが押された時の処理
    const onOpen = () => {
      usage.open(props.usage.id);
    };

    const isActionEnabledUsage = (targetUsage: GetMyUsage) => {
      if (!usage.isOrganizationUser.value || !usage.isRequestAll.value || isEnterprise) return true;
      return userId.value === targetUsage.user_id;
    };

    // init
    onBeforeMount(() => {
      responsive();
    });

    return {
      status: props.usage.status,
      date,
      isActive,
      isImageEnabled,
      isActionEnabled,
      evaluationModalRef,
      inquiryModalRef,
      error,
      evaluationFormKey,
      inquiryFormKey,
      userId,
      onHolderClicked,
      onDownload,
      onAlertClose,
      onReportButtonClick,
      onInquirySubmitted,
      onSubmit,
      onOpen,
      isActionEnabledUsage,
    };
  },
});
