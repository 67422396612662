<template>
  <div>
    <div v-if="error">
      <Instruction
        @close="onAlertClose"
        level="alert"
        position="fixed"
        :style="{ zIndex: 1000 }"
      >
        <p>{{ error }}</p>
        <a class="in-alert" @click="onReportButtonClick">お問い合わせ</a>
      </Instruction>
    </div>
    <div uk-modal ref="inquiryModalRef">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <InquiryForm
          @submitted="onInquirySubmitted"
          v-if="usage"
          :usageId="usage.id"
          :key="inquiryFormKey"
        />
      </div>
    </div>
    <div uk-modal="bg-close: false;" ref="evaluationModalRef">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <EvaluationForm
          :usageId="usage.id"
          v-if="usage"
          @submit="onSubmit"
          :key="evaluationFormKey"
        />
      </div>
    </div>
    <div
      ref="holder"
      class="uk-card uk-card-default"
      :class="{ active: isActive }"
      @click="onHolderClicked"
    >
      <div class="uk-card-body inner">
        <div class="column1">
          <div class="row">
            <span class="uk-text-small uk-text-italic">
              翻訳者: {{ usage.user_last_name }} {{ usage.user_first_name }}
            </span>
          </div>
          <div class="row multi-column">
            <div class="date">
              <span class="uk-text-small uk-text-italic">{{ date }}</span>
            </div>
            <div class="status">
              <StatusMark :status="status" />
            </div>
            <div class="filename">
              <span class="uk-text-small uk-text-italic">
                {{ usage.translation_file }}
              </span>
            </div>
          </div>
          <div class="multi-row">
            <div class="row">
              <span class="uk-text-default uk-text-bold">
                {{ usage.title_original }}
              </span>
            </div>
            <div class="row">
              <span class="uk-text-default uk-text-bold">
                {{ usage.title_translated }}
              </span>
            </div>
          </div>
          <div class="clamp" :class="{ active: isActive }">
            <span class="abstruct uk-text-small">
              {{ usage.abstract }}
            </span>
            <div></div>
          </div>
        </div>
        <div class="column2" v-if="isImageEnabled">
          <div class="images">
            <div class="image" :class="{ disabled: !usage.image }">
              <img
                :src="
                  !usage.image ? null : `data:image/png;base64,${usage.image}`
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="uk-card-footer" v-if="isActionEnabled">
        <div class="usage-meta" v-if="usage.status && usage.status < 400">
          <div>翻訳文字数: {{usage.consumed_word_count}}</div>
          <div>消費回数: {{usage.consumed_quota}}</div>
          <span class="uk-label" v-if="usage.is_ocr_used">OCR</span>
        </div>
        <div class="actions" :style="{ visibility: isActionEnabledUsage(usage) ? 'visible' : 'hidden' }">
          <a 
            href="javascript:void(0);" 
            @click.stop="onDownload" 
            v-if="usage.status && (usage.status === 200 || usage.status === 201)">
            Download
            <span uk-icon="download"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "@vue/runtime-core";
import { PropType } from "vue";
import moment from "moment";
import UIkit from "uikit";
import StatusMark from "../atoms/StatusMark.vue";
import Instruction from "../atoms/Instruction.vue";
import { EnterpriseApi, GetMyUsage, MyApi } from "../../autogen";
import { download } from "../../helpers/dom-utils";
import InquiryForm from "../molecules/InquiryForm.vue";
import EvaluationForm from "../molecules/EvaluationForm.vue";
import { useUsage } from "../compositions/useUsage";
import { useRoute } from "vue-router";
import { useApp } from "../compositions/useApp";

export default defineComponent({
  components: { StatusMark, Instruction, InquiryForm, EvaluationForm },
  props: {
    usage: {
      type: Object as PropType<GetMyUsage>,
      required: true,
    },
  },
  setup(props) {
    // create reactive object
    const route = useRoute();
    const usage = useUsage();
    const { userId } = useApp();
    const isActive = ref(false);
    const onHolderClicked = () => (isActive.value = !isActive.value);
    const date = moment(props.usage.uploaded_at).format("YYYY/MM/DD");
    const isImageEnabled = ref(false);
    const isActionEnabled = ref(false);
    const evaluationModalRef = ref<HTMLElement>();
    const inquiryModalRef = ref<HTMLElement>();
    const error = ref<string | null>(null);
    const evaluationFormKey = ref(0);
    const inquiryFormKey = ref(0);

    // エンタープライズ時用の情報を取得
    const isEnterprise = route.query.mode === "enterprise";
    const mode = isEnterprise ? "enterprise" : "my";
    const onDownload = async () => {
      error.value = null;
      try {
        const response = mode == "my" ? await new MyApi().downloadMyFile(
          { usageId: props.usage.id },
          { responseType: "arraybuffer" }
        ) : await new EnterpriseApi().downloadEnterpriseUserFile(
          { usageId: props.usage.id },
          { responseType: "arraybuffer" }
        );
        download(`${props.usage.translation_file}_翻訳済.docx`, response.data);
        document.body.onfocus = () => {
          if (evaluationModalRef.value) {
            evaluationFormKey.value = Date.now();
            UIkit.modal(evaluationModalRef.value).show();
          }
          document.body.onfocus = null;
        };
      } catch (err) {
        error.value = "ダウンロードに失敗しました";
      }
    };
    const onAlertClose = () => (error.value = null);
    const onReportButtonClick = () => {
      error.value = null;
      inquiryFormKey.value = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) UIkit.modal(inquiryModalRef.value).hide();
    };
    const onSubmit = () => {
      if (evaluationModalRef.value)
        UIkit.modal(evaluationModalRef.value).hide();
    };

    // responsive settings
    const responsive = () => {
      isImageEnabled.value = window.innerWidth < 850 ? false : true;
      isActionEnabled.value = window.innerWidth < 540 ? false : true;
    };
    window.addEventListener("resize", responsive);

    // "OPEN"ボタンが押された時の処理
    const onOpen = () => {
      usage.open(props.usage.id);
    };

    const isActionEnabledUsage = (targetUsage: GetMyUsage) => {
      if (!usage.isOrganizationUser.value || !usage.isRequestAll.value || isEnterprise) return true;
      return userId.value === targetUsage.user_id;
    };

    // init
    onBeforeMount(() => {
      responsive();
    });

    return {
      status: props.usage.status,
      date,
      isActive,
      isImageEnabled,
      isActionEnabled,
      evaluationModalRef,
      inquiryModalRef,
      error,
      evaluationFormKey,
      inquiryFormKey,
      userId,
      onHolderClicked,
      onDownload,
      onAlertClose,
      onReportButtonClick,
      onInquirySubmitted,
      onSubmit,
      onOpen,
      isActionEnabledUsage,
    };
  },
});
</script>

<style lang="scss" scoped>
.holder {
  background-color: #eee;
}
.inner {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  height: 150px;
  transition: height 0.3s 0s ease;
}
.active {
  transition: height 0.3s 0s ease;
  .inner {
    height: 300px;
  }
}
.column1 {
  min-width: 300px;
  text-align: left;
  flex: 1;
}
.column2 {
  height: 100%;
  margin-left: 12px;
}
.multi-column {
  display: flex !important;
  flex-direction: row;
  vertical-align: middle;
}
.clamp {
  flex: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;

  font-size: 1em;
  max-height: 12em;
}
.clamp.active {
  -webkit-line-clamp: 8;
}
.date {
  display: block;
  margin: 4px 0px;
}
.filename {
  padding: 4px 0px;
  margin-left: 10px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.status {
  padding: 2px 0px;
  margin-left: 5px;
}
.images {
  /* margin: 0px 15px; */
  height: 100%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}
.image {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  width: 140px;
  height: 140px;
  img {
    height: 100%;
    object-fit: contain;
  }
}
.image.disabled {
  background-color: grey;
}
.actions {
  height: 100%;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
}
.uk-card-default {
  margin-bottom: 16px;
}
.uk-card-footer {
  display: flex;
  justify-content: space-between;
  .actions {
    flex: 1;
    text-align: right;
  }
  .usage-meta {
    display: flex;
    div {
      margin-right: 12px;
    }
  }
}
</style>
