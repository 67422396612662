
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";
import Page from "../atoms/Page.vue";
import {
  GetMyProfileResponseMembership,
  GetMyUsage,
  MyApi,
  Profile,
} from "../../autogen";
import Instruction from "../atoms/Instruction.vue";
import Moment from "moment";
import { download } from "../../helpers/dom-utils";
import UIkit from "uikit";
import InquiryForm from "../molecules/InquiryForm.vue";
import EvaluationForm from "../molecules/EvaluationForm.vue";
type State = {
  disabled: boolean;
  profile: Profile | null;
  membership: GetMyProfileResponseMembership | null;
  translation_file: File | null;
  translating: boolean;
  translated_file: { name?: string; size?: number } | null;
  error: string | null;
  usage: GetMyUsage | null;
  evaluationFormKey: number;
  inquiryFormKey: number;
  downloading: boolean;
};
export default defineComponent({
  components: { Page, Instruction, InquiryForm, EvaluationForm },
  setup() {
    const myApi = new MyApi();
    const fileInput = ref<HTMLInputElement>();
    const evaluationModal = ref<HTMLElement>();
    const state: State = reactive({
      disabled: true,
      profile: null,
      membership: null,
      translation_file: null,
      translating: false,
      translated_file: null,
      error: null,
      usage: null,
      evaluationFormKey: 0,
      inquiryFormKey: 0,
      downloading: false,
    });

    async function updateState() {
      const response = await myApi.getMyProfile({});
      state.profile = response.data.profile;
      state.membership = response.data.membership;
      state.membership.contract_renewal_date = Moment(
        response.data.membership.contract_renewal_date
      ).format("YYYY年MM月DD日");
    }
    onBeforeMount(async () => {
      await updateState();
    });
    const onUploadFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files![0].size > 52428800) {
        fileInput.value!.value = "";
        setTimeout(() => {
          state.error = "翻訳ファイルのサイズの上限は50MBです";
        });
        return;
      }
      state.translation_file = target.files![0];
    };
    const onFileDrop = (event: DragEvent) => {
      if (state.membership!.quota === 0) return;
      const files = event.dataTransfer!.files;
      state.translation_file = files[0];
    };
    const onUploadFileCloseButtonClick = () => {
      fileInput.value!.value = "";
      state.translation_file = null;
      state.translating = false;
      state.translated_file = null;
      state.error = null;
    };
    let interval: number;
    const onUploadButtonClick = async () => {
      state.translating = true;
      state.error = null;
      state.usage = null;
      try {
        const uploadResponse = await myApi.uploadMyFile({
          file: state.translation_file!,
        });
        // const uploadResponse = { status: 200, data: { id: 0 } };
        if (uploadResponse.status === 200) {
          interval = window.setInterval(async () => {
            const usageResponse = await myApi.getMyUsage({
              usageId: uploadResponse.data.id!,
            });
            // 翻訳中じゃない
            if (usageResponse.data.status && usageResponse.data.status !== 202) {
              state.translating = false;
              clearInterval(interval);
              // エラー終了
              if (usageResponse.data.status !== 200 && usageResponse.data.status !== 201) {
                if (usageResponse.data.err_state == "1") {
                  state.error = '翻訳に失敗しました<br/>文字データが埋め込まれていない（文字データをコピー＆ペーストできない）PDF です。<br/>翻訳には文字認識（OCR）機能が必要です。<br/>スタンダートもしくはエンタープライズプランをご利用ください。';
                }
                else {
                  state.error = "翻訳に失敗しました";
                }
              }
              // 翻訳完了
              else {
                state.translated_file = {};
                state.usage = usageResponse.data;
              }
            }
          }, 1000);
        }
      } catch (err) {
        console.error(err);
        clearInterval(interval);
        state.translating = false;
        state.error = "翻訳に失敗しました";
      }
    };
    const onDownloadButtonClick = async () => {
      state.error = null;
      state.downloading = true;
      try {
        const response = await myApi.downloadMyFile(
          {
            usageId: state.usage!.id,
          },
          {
            responseType: "arraybuffer",
          }
        );
        // const timeout = () => {
        // 	return new Promise((resolve, reject) => {
        // 		setTimeout(() => {
        // 			resolve(null)
        // 		}, 5000)
        // 	})
        // }
        // await timeout()
        // const response = {data: 'aaaa'}
        await updateState();
        download(`${state.translation_file!.name}_翻訳済.docx`, response.data);
        document.body.onfocus = () => {
          if (evaluationModal.value) {
            state.evaluationFormKey = Date.now();
            UIkit.modal(evaluationModal.value).show();
          }
          document.body.onfocus = null;
        };
      } catch (err) {
        state.error = "ダウンロードに失敗しました";
      }
      state.downloading = false;
    };
    onBeforeUnmount(() => {
      if (interval) {
        clearInterval(interval);
      }
    });
    const onAlertClose = () => {
      state.error = null;
    };
    const onInquiryButtonClick = () => {
      state.inquiryFormKey = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const inquiryModalRef = ref<HTMLElement>();
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const onSubmit = () => {
      if (evaluationModal.value) {
        UIkit.modal(evaluationModal.value).hide();
      }
    };
    const fileSize = (size?: number) => {
      return size !== undefined
        ? `${Math.round((size / 1024 / 1024) * 10) / 10}MB`
        : "-";
    };
    const fileName = (name: string) => name;
    return {
      fileInput,
      evaluationModal,
      state,
      onUploadFileChange,
      onUploadFileCloseButtonClick,
      onUploadButtonClick,
      onAlertClose,
      onFileDrop,
      onDownloadButtonClick,
      onInquiryButtonClick,
      onInquirySubmitted,
      inquiryModalRef,
      onSubmit,
      fileSize,
      fileName,
    };
  },
});
