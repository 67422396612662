<template>
  <div class="container">
    <div class="row">
      <span class="uk-text-small">利用済み本数 / 契約本数</span>
      <span class="uk-text-small">{{ value }} / {{ max }}</span>
    </div>
    <div class="row">
      <progress class="uk-progress progress-color" :value="value" :max="max" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  setup() {},
});
</script>

<style scoped>
.container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 15px 0px 15px;
}
.row {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
progress {
  width: 100%;
  margin-bottom: 10px;
}
.uk-progress.progress-color::-webkit-progress-value {
  background-color: LightGreen;
}
.uk-progress.progress-color::-moz-progress-bar {
  background-color: LightGreen;
}
.uk-progress.progress-color::-ms-fill {
  background-color: LightGreen;
}
</style>
