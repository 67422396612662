<template>
  <form @submit="onSubmit">
    <Instruction
      v-if="state.instruction || state.instruction"
      @close="onAlertClose"
      :level="state.instruction.level"
      position="relative"
      :closable="state.instruction.level !== 'info'"
    >
      <p v-html="state.instruction.message"></p>
      <router-link
        v-if="state.instruction.level === 'alert'"
        to="/reset_password_request"
        >再リセット</router-link
      >
    </Instruction>
    <div>
      <div class="uk-card-body">
        <div v-if="!state.reset">
          <fieldset class="uk-fieldset">
            <FieldLabel title="パスワード" :required="true" />
            <input
              class="uk-input"
              type="password"
              name="password"
              v-model="password"
              v-clear-instruction
            />
            <FieldError name="password" />
          </fieldset>
        </div>
      </div>
      <div class="uk-card-footer reset">
        <button
          v-if="!state.reset"
          type="submit"
          :disabled="isSubmitting"
          class="uk-button uk-button-primary uk-margin-medium-top"
          v-clear-instruction
        >
          リセット
        </button>
        <button
          v-if="state.reset"
          type="button"
          @click="onLoginButtonClick"
          :disabled="isSubmitting"
          class="uk-button uk-button-primary uk-margin-medium-top"
          v-clear-instruction
        >
          ログイン
        </button>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
@include public-form-card;
@include public-form-card-footer;
@include form-input;
</style>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { MyApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import { useI18n } from 'vue-i18n';
import Instruction from '../atoms/Instruction.vue';
import { Instruction as InstructionType } from '../../types';
type State = { instruction?: InstructionType; reset: boolean };
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const { handleSubmit, isSubmitting } = useForm({
      validationSchema: yup.object({
        password: yup
          .string()
          .required(t('required', { field: 'パスワード' }))
          .matches(
            /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/,
            t('password')
          ),
      }),
    });
    const state: State = reactive({ reset: false });
    function clearState() {
      state.instruction = undefined;
    }
    const { value: password } = useField('password');
    const onSubmit = handleSubmit(async (values) => {
      clearState();
      try {
        await new MyApi().reset({
          authorization: new URLSearchParams(location.search).get('token')!,
          resetRequest: { password: values.password! },
        });
        state.instruction = {
          level: 'info',
          message: 'パスワードのリセットが完了しました',
        };
        state.reset = true;
      } catch (err) {
        state.instruction = {
          level: 'alert',
          message:
            'パスワードのリセットに失敗しました<br/>URLが不正または期限切れです',
        };
        state.reset = false;
      }
    });
    const onAlertClose = () => {
      clearState();
    };
    const onLoginButtonClick = () => {
      router.push('/login');
    };
    return {
      t,
      state,
      onSubmit,
      isSubmitting,
      password,
      onLoginButtonClick,
      onAlertClose,
    };
  },
});
</script>
