
import { defineComponent, onMounted, ref } from 'vue';
import UIkit from 'uikit';
export default defineComponent({
  props: {
    position: {
      type: String,
    },
    level: {
      type: String,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  setup(_props, context) {
    const alertRef = ref<HTMLElement>();
    onMounted(() => {
      if (_props.level !== 'alert') {
        if (_props.closable) {
          setTimeout(() => {
            if (alertRef.value) {
              UIkit.alert(alertRef.value).close();
            }
          }, _props.timeout);
        }
      }
      // @ts-ignore
      UIkit.util.on('[uk-alert]', 'hide', () => {
        context.emit('close');
      });
    });
    return { alertRef };
  },
});
