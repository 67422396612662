// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export async function download(name: string, data: any) {
  const url = window.URL.createObjectURL(
    new Blob([(data as unknown) as string])
  );
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.setAttribute('href', url);
  anchor.setAttribute('download', name);
  anchor.click();
  anchor.remove();
}
