import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

import Login from "../components/pages/Login.vue";
import Main from "../components/pages/Main.vue";
import PasswordResetRequest from "../components/pages/PasswordResetRequest.vue";
import PasswordReset from "../components/pages/PasswordReset.vue";
import Plan from "../components/pages/Plan.vue";
import Profile from "../components/pages/Profile.vue";
import Usage from "../components/pages/Usage.vue";
import Register from "../components/pages/Register.vue";
import PublicHeader from "../components/atoms/PublicHeader.vue";
import Menu from "../components/atoms/Menu.vue";
import Verify from "../components/pages/Verify.vue";
import Enterprise from "../components/pages/Enterprise.vue";
import Store from "../store";
import { MyApi } from "../autogen";
import { trackRouter } from "vue-gtag-next";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    components: { default: Login, "public-header": PublicHeader },
    name: "Login",
    props: { isPublic: true },
  },
  {
    path: "/reset_password_request",
    components: {
      default: PasswordResetRequest,
      "public-header": PublicHeader,
    },
    name: "PasswordResetRequest",
    props: { isPublic: true },
  },
  {
    path: "/reset_password",
    components: { default: PasswordReset, "public-header": PublicHeader },
    name: "PasswordReset",
    props: { isPublic: true },
  },
  {
    path: "/verify",
    components: { default: Verify, "public-header": PublicHeader },
    name: "Verify",
    props: { isPublic: true },
  },
  {
    path: "/plan",
    components: { default: Plan, menu: Menu },
    name: "Plan",
  },
  {
    path: "/profile",
    components: { default: Profile, menu: Menu },
    name: "Profile",
  },
  {
    path: "/usage",
    components: { default: Usage, menu: Menu },
    name: "Usage",
  },
  {
    path: "/register",
    components: { default: Register, "public-header": PublicHeader },
    name: "Register",
    props: { isPublic: true },
  },
  {
    path: "/",
    components: { default: Main, menu: Menu },
    name: "Main",
  },
  {
    path: "/enterprise",
    components: { default: Enterprise, menu: Menu },
    name: "Enterprise",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const publicPaths = ["/reset_password", "/register", "/verify", "/login"];
router.beforeEach(
  (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.matched.some((rec) => publicPaths.includes(rec.path))) {
      next();
    } else {
      const auth = Store.getInstance().getAuth();
      if (auth) {
        next();
      } else {
        new MyApi()
          .auth({})
          .then((_response) => {
            Store.getInstance().setAuth(true);
            if (to.matched.some((rec) => "/login" === rec.path)) {
              next({ path: "/login" });
            } else {
              next();
            }
          })
          .catch(() => {
            next({ path: "/login", query: { redirect: to.fullPath } });
          });
      }
    }
  }
);
trackRouter(router);
export default router;
