import { EnterpriseApi } from "@/autogen";
import { ref } from "vue";
import { GetEnterpriseUsers } from "@/autogen";
import { useNotification } from "./useNotification";

// 消費済み本数
const consumedUsages = ref<number>(0);

// 契約本数
const contractedUsages = ref<number>(0);

// メンバーリスト
const members = ref<GetEnterpriseUsers[]>([]);

// CompositionAPI
// エンタープライズに関する処理をまとめるコンポジション
export function useEnterprise() {
  return {
    consumedUsages,
    contractedUsages,
    members,
    getMembers,
    getUsages,
  };
}

/**
 * メンバー情報取得用の関数
 * @returns
 */
async function getMembers(): Promise<void> {
  try {
    // APIサーバーへメンバー情報のリクエスト
    // JWTのユーザーIDより、組織を解決してメンバーを返してくる
    const api = new EnterpriseApi();
    const res = await api.getEnterpriseUsers({});
    if (res.status !== 200) throw new Error();

    // メンバー情報の更新
    members.value = res.data;
  } catch {
    useNotification().error("メンバー情報の取得に失敗しました");
  }
}

/**
 * 消費本数量の取得用関数
 * @returns
 */
async function getUsages(): Promise<void> {
  try {
    // APIサーバーへ契約本数と消費本数の取得をリクエスト
    const api = new EnterpriseApi();
    const res = await api.getEnterpriseQuota({});
    if (res.status !== 200) throw new Error();

    // 本数の更新
    consumedUsages.value = res.data.consumedUsages ?? 0;
    contractedUsages.value = res.data.contractedUsages ?? 0;
  } catch {
    useNotification().error("消費本数量の取得に失敗しました");
  }
}
