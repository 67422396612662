<template>
  <div class="page-container">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.page-container {
  width: 100%;
  position: relative;
}
@media screen and (min-width: 896px) {
  .page-container {
    width: $max-page-width;
  }
}
</style>
