
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useWindow } from "./components/compositions/useWindow";
export default defineComponent({
  setup() {
    const route = useRoute();
    const state = reactive({ page: route.name, isPublic: true });
    watch(
      () => route.name,
      (name) => {
        state.page = name;
        state.isPublic = [
          "Login",
          "PasswordResetRequest",
          "PasswordReset",
          "Register",
          "Verify",
        ].includes(name as string);
      }
    );

    // ウィンドウサイズ変更時のイベントを追加して、
    // アプリ内で使用するウィンドウに関するパラメータを更新する
    const wnd = useWindow();
    wnd.update();
    window.addEventListener("resize", wnd.update);
    return { state };
  },
});
